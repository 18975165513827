import Form from '../template/Form';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const ContactPage = () => {
    const variants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut' }
        }
    }

    return(
        <motion.div variants={variants} initial="hidden" animate="visible">
            <Navbar />
            <div className='contact-form'>
                <div className='contact'>
                    <Form />
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}

export default ContactPage;