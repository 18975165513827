import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const Menu = () => {
    return(
        <div className="dropdown-menu">
            <div className="close-menu">
            <Link to={'/'}><button>X</button></Link>
            </div>
            <ul>
                <NavLink to={'/'}><motion.li initial={{ x: '-100vw' }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>Home</motion.li></NavLink>
                <NavLink to={'/about'}><motion.li initial={{ x: '100vw' }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>About</motion.li></NavLink>
                <NavLink to={'/locations'}><motion.li initial={{ x: '-100vw' }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>Where to Find Us</motion.li></NavLink>
                <NavLink to={'/parties'}><motion.li initial={{ x: '100vw' }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>Parties</motion.li></NavLink>
                <NavLink to={'/contact'}><motion.li initial={{ x: '100vw' }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>Contact Us</motion.li></NavLink>
                <NavLink to={'http://www.bookwhen.com/puddymuddlesplaygroup'} style={{ textShadow: '-1.5px 1.5px #fff' }} target="_blank"><motion.li initial={{ x: '-100vw' }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>Book Now</motion.li></NavLink>
            </ul>
        </div>
    )
}

export default Menu;