import { motion } from 'framer-motion';
import Navbar from '../components/Navbar'
import PartyGallery from '../components/PartyGallery';
import Invite from '../components/Invite';
import Footer from '../components/Footer';
import Reviews from '../components/Reviews';

const PartiesPage = () => {
    const variants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }
    
    return(
        <motion.div variants={variants} initial="hidden" animate="visible">
        <header className="App-parties-header">
          <Navbar />
        </header>
            <Invite />
            <PartyGallery />
            <Reviews />
            <Footer />
        </motion.div>
    )
}

export default PartiesPage;