import { Link } from 'react-router-dom';
import daisy from '../images/daisy12.webp';
import daisy1 from '../images/daisy1.webp';
import daisy3 from '../images/daisy7.webp';
import daisy4 from '../images/daisy9.webp';
import daisy5 from '../images/daisy5.webp';
import daisy6 from '../images/daisy6.webp';
import daisy7 from '../images/daisy11.webp';
import daisy8 from '../images/daisy8.webp';

const Daisy = () => {
    return (
        <div>
            <div className='electric-daisy'>
                <div className='daisy'>
                    <div className='daisy-title'>
                        <h3>Electric Daisy</h3>
                    </div>
                    <div className='daisy-blurb'>
                        <p>During the summer holidays we will be running sessions from the brand new community
                            garden at Electric Daisy (Bold Lane).</p>
                        <p>We are taking things to the next level by introducing your children to nature in an exciting
                            and innovative way! We will be combining urban junkyard elements with rustic mud kitchens,
                            enchanting nature crafts, and hands-on gardening.</p>
                        <p>Located right in the heart of the city, our new venture is easily accessible to everyone.
                            Whether you're a regular or a new face, we cannot wait to share this exciting experience with
                            you!</p>
                    </div>
                    <div className='daisy-img'>
                        <img src={daisy1} alt='darley abbey' />
                    </div>
                    <div className='daisy-address'>
                    <p>Puddy Muddles</p>
                    <p>@</p>
                    <h3>Electric Daisy</h3>
                    <p>1-4 Bold Lane</p>
                    <p>Derby</p>
                    <p>DE1 3NT</p>
                    <p>Children over 6 months are £7, two siblings can attend for £11.50 and children under 6 months are free with a paying sibling.</p>
                    <Link to='http://www.bookwhen.com/puddymuddlesplaygroup'  target='_blank'>
                        <button>Book Now</button>
                    </Link>
                </div>
            </div>
                <div className='muddler-img'>
                    <img src={daisy} alt='daisy' />
                </div>
        </div>
            <div className='daisy-gallery'>
                <img src={daisy8} alt='electric daisy' />
                <img src={daisy3} alt='electric daisy' />
                <img src={daisy5} alt='electric daisy' />
                <img src={daisy4} alt='electric daisy' />
                <img src={daisy6} alt='electric daisy' />
                <img src={daisy7} alt='electric daisy' />
            </div>
        </div>
    )
}

export default Daisy;