import party7 from '../images/party7.webp';
import party3 from '../images/party3.webp';
import party4 from '../images/darley5.webp';
import party5 from '../images/party5.webp';
import party6 from '../images/party6.webp';
import party8 from '../images/party8.webp';

const PartyGallery = () => {
    return(
        <div className='party-gallery'>
            <img src={party7} alt='darley abbey' />
            <img src={party5} alt='darley abbey' />
            <img src={party4} alt='darley abbey' />
            <img src={party3} alt='darley abbey' />
            <img src={party6} alt='darley abbey' />
            <img src={party8} alt='darley abbey' />
        </div>
    )
}

export default PartyGallery;