import logo from '../images/pm_logo.webp';
import { CgMenu } from 'react-icons/cg';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';


const Navbar = () => {
    return(
        <div className='navbar'>
            <div className='logo'>
                <Link to={'/'} style={{ textDecoration: 'none'}}><img src={logo} style={{ position: 'absolute', left: '-90px', top: '-90px', height:'120px', width: '250px', backgroundColor: 'var(--mud)', borderRadius: '50%', padding: '100px 50px 20px 100px' }} alt='puddy muddles' /></Link>
            </div>
            <div className='body-list'>
                    <ul>
                        <li><Link to={'/'} style={{ textDecoration: 'none', color: '#f8f8f8' }}>Home</Link></li>
                        <li><Link to={'/about'} style={{ textDecoration: 'none', color: '#f8f8f8' }}>About</Link></li>
                        <li><Link to={'/locations'} style={{textDecoration: 'none', color: '#f8f8f8' }}>Where to Find Us</Link></li>
                        <li><Link to={'/parties'} style={{ textDecoration: 'none', color: '#f8f8f8' }}>Parties</Link></li>
                        <li><Link to='http://www.bookwhen.com/puddymuddlesplaygroup' target='_blank' style={{ textDecoration: 'none', color: 'var(--flowers)', textShadow: '0.5px 0.5px #fff' }}>Book Now</Link></li>
                        <li><Link to={'/contact'} style={{ textDecoration: 'none', color: '#f8f8f8' }}>Contact Us</Link></li>
                    </ul>
            </div>
            <div className='icons'>
                <IconContext.Provider
                    value={{ style: { backgroundColor: 'var(--mud)', padding: 10, borderRadius: '50% 50% 50% 0', color: 'var(--flowers)', fontSize: '40px' } }}>
                <Link to='/menu'><CgMenu /></Link>
            </IconContext.Provider>
            </div>
        </div>
    )
}

export default Navbar;