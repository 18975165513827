import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import about from '../images/about.webp';
import profilepic from '../images/rom.webp';
import staffpic from '../images/claire1.webp';
import staff from '../images/staff.webp';
import hair from '../images/fe.webp';
import boots from '../images/about-blurb.webp';
import stripes from '../images/stripes.png';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Reviews from '../components/Reviews';

const AboutPage = () => {
    const variants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    const slideIn = {
        hidden: {
            y: '10vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeOut' }
        },
    }
    
    return(
        <motion.div className='about' variants={variants} initial='hidden' animate='visible'>
        <header className="App-about-header">
          <Navbar />
        </header>
            <div className='headline'>
                <img src={about} style={{ height: '100px', width: '286.5px' }} alt='about' />
            </div>
            <div className='blurb'>
                <motion.div className='blurb-intro' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                <p>It's no secret that kids thrive in the great outdoors, and Puddy Muddles Playgroup is the
                    perfect place for them to do just that. Our focus on outdoor play and social interaction is
                    designed to help children develop important skills while also having fun. Here are just a few
                    of the benefits our playgroup offers:
                    </p>
                <ul>
                    <li>Encourages a love of nature: By spending time outside and engaging in activities such
                        as nature crafts and mud kitchens, children develop a deeper appreciation for the
                        natural world around them.</li>
                    <li>Promotes physical activity: Swings, hammocks, and other outdoor equipment get kids
                        moving and help them stay active and healthy.</li>
                    <li>Fosters social skills: Playing with other children helps kids learn how to share,
                        communicate, and work together.</li>
                    <li>Sparks creativity: Construction play allows kids to use their imaginations and build
                        whatever they can dream up.</li>
                </ul>
                <p>At Puddy Muddles Playgroup, we believe that outdoor play is essential for children's growth
                    and development, and we're committed to providing a safe, fun, and stimulating
                    environment for kids to explore and learn in. We can help them develop a lifelong
                    appreciation for the environment and equip them with valuable life skills that will serve them
                    well into adulthood.</p>
                </motion.div>
                <div className='blurb-intro-img'>
                    <img src={stripes} alt="stripes" />
                    <img src={hair} alt="hair" />
                </div>
                <motion.div className='blurb-benefits' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                <h4>The Benefits</h4>
                <p>To further promote the benefits of spending time outdoors for children, here are some
                    additional facts to consider:</p>
                <ul>
                    <li>Outdoor play and exploration can improve creativity and imagination in children.</li>
                    <li>Exposure to natural sunlight can increase vitamin D levels, which is essential for healthy
                        bone growth and immune system function.</li>
                    <li>Studies have shown that children who spend more time in nature have lower rates of
                        obesity, and anxiety.</li>
                    <li>Nature-based activities can also improve problem-solving skills, as children learn to
                        navigate and overcome obstacles in their environment.</li>
                    <li>Spending time in nature can foster a sense of appreciation and respect for the
                        environment, leading to more sustainable behaviors in the future.</li>
                </ul>
                <p>By incorporating nature-based activities and encouraging outdoor play, we can help
                    children develop physical, cognitive, and social skills, while also promoting a lifelong love
                    and respect for the natural world.</p>
                <button><Link to='http://www.bookwhen.com/puddymuddlesplaygroup' style={{ color: 'var(--clouds)', textDecoration: 'none' }} target='_blank'>Book Now</Link></button>
                </motion.div>
                <div className='blurb-benefits-img'>
                    <img src={boots} alt="boots" />
                </div>
            </div>
            <div className='pricing-price'>
            <div className='price'>
                <h4>Pricing</h4>
            </div>
            <div className='pricing'>
                <p>Child - £7</p>
                <p>Siblings - £11.50</p>
                <p>Childminder - £15</p>
                <p>Siblings under 6 months - FREE</p>
            </div>
            </div>
                <div className='staff'>
                    <motion.div className='staff-img' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <img src={staffpic} alt='Staff' />
                    </motion.div>
                    <motion.div className='staff-blurb' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <h4>Claire Roberts</h4>
                        <p>As the driving force behind the playgroup, Claire manages its daily operations, infuses it with
                            creative flair and develops many of the resources.</p>
                        <p>Her enthusiasm for nature and creating exciting educational activities for children is evident
                            in all that she does.</p>
                        <p>Striving to widen her knowledge,  Claire has now completed and passed her Level 3 Forest School Leadership course and is awaiting certification. She is also qualified in outdoor and pediatric first aid.</p>
                    </motion.div>
                </div>
                <div className='staff'>
                    <motion.div className='staff-img' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <img src={staff} alt='Staff' />
                    </motion.div>
                    <motion.div className='staff-blurb' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <h4>Jordan Hazell</h4>
                        <p>Having worked in Community Engagement and Events at various charities, Jordan is keen to engage people of all ages with nature.</p>
                        <p>She has experience working with children from 0-18 years old, as well as adults. Having recently taken on the role as General Manager at Electric Daisy, she can't wait to see the mud kitchen and garden being used to its full potential with Puddy Muddles Stay and Play sessions.</p>
                    </motion.div>
                </div>
                <div className='profile'>
                    <motion.div className='profile-img' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <img src={profilepic} alt='staff' />
                    </motion.div>
                    <motion.div className='profile-blurb' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <h4>Romeana Juffs</h4>
                        <p>From November 2020 to June 2021, Romeana successfully established and led Puddy
                            Muddles Playgroup before handing over the baton to Claire. Although she remains a silent
                            partner, Romeana continues to oversee administrative duties, marketing, resources, and
                            social media.</p>
                        <p>Her passion for nature is evident in her work, and is also driven by her two nature-loving
                            boys. Romeana's expertise lies in working with adults and children with autism.</p>
                    </motion.div>
                </div>
            <Reviews />
            <Footer />
        </motion.div>
    )
}

export default AboutPage;