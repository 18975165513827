import Body from "../components/Body";
import { motion } from 'framer-motion';
import Reviews from "../components/Reviews";
import Footer from '../components/Footer';

const HomePage = () => {
const variants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { duration: 1, ease: 'easeInOut' }
    }
}

    return(
        <motion.div variants={variants} initial="hidden" animate="visible">
            <Body />
            <Reviews />
            <Footer />
        </motion.div>
    )
}

export default HomePage;