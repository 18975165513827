const Admin = () => {
    return(
        <>
            <div className="admin">
                <h1>Puddy Muddles Admin</h1>
            </div>
        </>
    )
}

export default Admin;