const Reviews = () => {
    return(
        <div>
            <div className='review-border'></div>
            <div className="reviews">
            <div className='banner'>
                <h2>"The only problem with Puddy Muddles is that it wasn't about soon enough! It's the kind of play session that all kids (and parents!) need in their lives! Always fun, always imaginative and affordable at the same time. Outside is always best for sure.🙌🌻"</h2>
                <h3>Lucy</h3>
            </div>
            <div className='banner'>
                <h2>"Thank you so much, it was absolutely fab! We will definitely be back!❤️"</h2>
                <h3>Lia</h3>
            </div>
            <div className='banner'>
                <h2>"Maximus had a brilliant birthday party and all his friends had fun! Lots of comments from parents saying what a great venue and idea for a birthday party. Quote: "You've found a gem"."</h2>
                <h3>Mayha, Edd and Maximus</h3>
            </div>
            </div>
        </div>
    )
}

export default Reviews;