import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import './fonts/Now-Regular.woff';
import './fonts/Now-Thin.woff';
import './fonts/Now-Light.woff';
import './fonts/Now-Medium.woff';
import './fonts/Now-Bold.woff';
import './fonts/Now-Black.woff'
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import LocationsPage from './pages/LocationsPage';
import PartiesPage from './pages/PartiesPage';
import ContactPage from './pages/ContactPage';
import Menu from './pages/Menu';
import InfoPage from './pages/InfoPage';
import Admin from './pages/Admin';
import LogIn from './LogIn';

const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path='/' element={<App />}>
        <Route index={true} path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/parties' element={<PartiesPage />} />
        <Route path='/locations' element={<LocationsPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/info' element={<InfoPage />} />
        <Route path='/puddyadmin' element={<Admin />} />
        <Route path='/login' element={<LogIn />} />
      </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);