import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBTykTpfG9Q4__JCMoilun0BeJbuiXkrzo",
  authDomain: "puddymuddles-7ab4d.firebaseapp.com",
  projectId: "puddymuddles-7ab4d",
  storageBucket: "puddymuddles-7ab4d.appspot.com",
  messagingSenderId: "592610459403",
  appId: "1:592610459403:web:9cbea65a91f2e322096c0e"
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);