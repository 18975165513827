import { FaFacebookSquare, FaInstagram, FaCalendarCheck } from 'react-icons/fa'
import { IconContext } from "react-icons";
import { Link } from 'react-router-dom';

const Footer = () => {
    const date = () => {
        return new Date().getFullYear();
    }

    return (
        <div className="footer">
            <IconContext.Provider
                value={{ style: { color: 'var(--mud)', padding: '20px 10px 0 10px', fontSize: '30px' } }}
            >
                <div>
                    <Link to={'https://www.facebook.com/people/puddymuddlesplaygroup/100064040203304/'} target='_blank'><FaFacebookSquare /></Link>
                    <Link to={'https://www.instagram.com/puddymuddlesplaygroup'} target='_blank'><FaInstagram /></Link>
                    <Link to={"https://bookwhen.com/puddymuddlesplaygroup#focus=ev-slvd-20231117094500"} target="_blank"><FaCalendarCheck /></Link>
                </div>
            </IconContext.Provider>
            <div><h4>Puddy Muddles &copy;{date()}</h4></div>
        </div>
    )
}

export default Footer;