const Info = () => {
    return(
        <div className='info-menu'>
                <div className='info-blurb' initial={{ y: '40vh', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} viewport={{ once: true }} transition={{ duration: 1 }}>
                    <div className='info-text'>
                        <h2>Puddy Muddles at Castle Donington</h2>
                        <p>Puddy Muddles is in the process of planning a new adventure in Castle Donington.</p> 
                        
                        <p>We need your help!</p>

                        <p>To enable us to gauge interest, add your name and email address to the contact form and we will get back to you.</p>
                    </div>
                </div>
            </div>
    )
}

export default Info;