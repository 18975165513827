import { Link } from 'react-router-dom';
import muddler from '../images/puddy-muddler.webp';
import darley1 from '../images/darley1.webp';
import darley2 from '../images/darley2.webp';
import darley3 from '../images/darley3.webp';
import darley4 from '../images/darley4.webp';
import darley5 from '../images/darley5.webp';
import darley6 from '../images/darley6.webp';
import darley7 from '../images/party8.webp';

const Darley = () => {
    return (
        <div>
            <div className='locations'>
                <div className='muddler-img'>
                    <img src={muddler} alt='muddler' />
                </div>
                <div className='darley'>
                    <div className='darley-title'>
                        <h3>Darley Abbey</h3>
                    </div>
                    <div className='darley-blurb'>
                        <p>Our sessions take place amidst the beautifully kept woodland area of the old vicarage school every Tuesday, Friday &
                            Saturday. The location is ideal for allowing children to explore, create and be at one with nature. We also use this
                            delightful space for childrens' birthday parties during the weekends.</p>
                        <p>We believe in the importance of connecting with nature and fostering a love for the
                            environment. Our playgroup sessions and birthday parties provide the perfect opportunity
                            for children to do just that. Come and join us in this serene space and let your
                            child experience the wonders of the natural world.</p>
                    </div>
                    <div className='darley-img'>
                        <img src={darley1} alt='darley abbey' />
                    </div>
                    <div className='darley-address'>
                    <p>Puddy Muddles</p>
                    <p>@</p>
                    <h3>Darley Abbey</h3>
                    <p>Old Vicarage School</p>
                    <p>11 Church Lane</p>
                    <p>Derby</p>
                    <p>DE22 1EW</p>
                    <p>Children over 6 months are £7.00, two siblings can attend for £11.50 and children under 6 months are free with a paying sibling.</p>
                    <Link to='http://www.bookwhen.com/puddymuddlesplaygroup'  target='_blank'>
                        <button>Book Now</button>
                    </Link>
                </div>
            </div>
        </div>
            <div className='darley-gallery'>
                <img src={darley2} alt='darley abbey' />
                <img src={darley3} alt='darley abbey' />
                <img src={darley4} alt='darley abbey' />
                <img src={darley5} alt='darley abbey' />
                <img src={darley6} alt='darley abbey' />
                <img src={darley7} alt='darley abbey' />
            </div>
        </div>
    )
}

export default Darley;