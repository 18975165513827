import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import fwr1 from '../images/flower1.png';
import fwr2 from '../images/flower2.png';
import date2 from '../images/parties-link.webp';
import date1 from '../images/darley1.webp';
import dirt from '../images/growing & learning.png';
import Navbar from './Navbar';
import button from '../images/button.png';

const Body = () => {
    const slideIn = {
        hidden: {
            y: '10vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeOut' }
        },
    }

    return(
        <div>
        <header className="App-header">
            <Navbar />
            <div className='head'>
            </div>
        </header>
            <div>
                <div className='welcome'>
                    <h2>Welcome to Puddy Muddles Playgroup!</h2>
                    <img src='https://firebasestorage.googleapis.com/v0/b/puddymuddles-7ab4d.appspot.com/o/bubbles.webp?alt=media&token=97ca3916-7506-4cfc-aace-ee7f12834322' alt='bubbles' />
                    <div className='sidebar'>
                    </div>
                    <motion.div className='para' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                        <ul>
                            <li>At Puddy Muddles Playgroup, we promote outdoor play as an essential means for childhood growth
                                and development.</li> 
                            <li>We are committed to providing a safe, fun, and stimulating
                                environment for kids to explore and learn.</li> 
                            <li>We can help to ensure they develop a lifelong appreciation for the environment 
                                and equip them with valuable life skills that will serve them
                                well into adulthood.</li>
                        </ul>
                    </motion.div>
                    <div><button><Link to={'/about'}>Learn more...</Link></button></div>
                </div>
        </div>
        <div className='body-main'>
            <img src={fwr1} alt='flower' />
            <img src={dirt} alt='I dig dirt' />
            <img src={fwr2} alt='flower' />
        </div>
        <div className='main-footer'>
            <h3>What's On?</h3>
            <motion.div className='event' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
                <Link to={'/locations'}><img src={date1} alt='' /></Link>
                <div className='event-title'>
                    <p>Puddy Muddles</p>
                    <p>@</p>
                    <h4>Darley Abbey</h4>
                </div>
                <p>A beautifully kept woodland area where children can
                     explore, create and be at one with nature.</p>
                     <button><Link to='http://www.bookwhen.com/puddymuddlesplaygroup' target='_blank'>Book Now</Link></button>
            </motion.div>
            <motion.div className='event' variants={slideIn} initial='hidden' whileInView='visible' viewport={{ once: true }}>
            <Link to={'/parties'}><img src={date2} alt='' /></Link>
                <div className='event-title'>
                    <p>Puddy Muddles</p>
                    <p>&</p>
                    <h4>Parties</h4>
                </div>
                <p>Imagine celebrating your child's special day in a beautiful and natural setting, surrounded
                    by friends and family.</p>
                    <button><Link to='http://www.bookwhen.com/puddymuddlesplaygroup' target='_blank'>Book Now</Link></button>
            </motion.div>
        </div>
        </div>
    )
}

export default Body;