import { useState } from 'react';
import emailjs from 'emailjs-com';
import Info from './Info';

function InfoForm() {
  const [formData, setFormData] = useState({
    name: '',
    subject: 'Castle Donington',
    email: '',
    message: 'This is to register my interest in Puddy Muddles at Castle Donington. Please keep me informed of any future developments.'
  });

  const [feedback, setFeedback] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and user ID here
    const serviceID = 'service_ntdvtvq';
    const templateID = 'template_jxicvds';
    const userID = 'X4T1QAONrMPFYFEBm';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFeedback('Message sent successfully!');
        setFormData({
          name: '',
          subject: '',
          email: '',
          message: ''
        }); // Clear the form
      }, (err) => {
        console.log('FAILED...', err);
        setFeedback('Failed to send message. Please try again later.');
      });
  };

  return (
    <div className='info'>
    <Info />
    <div className='form'>
      <div>
      <h2>Castle Donington</h2>
      </div>
    <form onSubmit={handleSubmit}>
      <input type="text" name="name" placeholder="Your name" value={formData.name} onChange={handleChange} required />
      <input type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
      <input type="email" name="email" placeholder="Your email" value={formData.email} onChange={handleChange} required />
      <textarea name="message" placeholder="Your message" value={formData.message} onChange={handleChange} required />
      <button type="submit">Send</button>
      {feedback && <div className={feedback.startsWith('Failed') ? 'error' : 'success'}>{feedback}</div>}
    </form>
    </div>
    </div>
  );
}

export default InfoForm;