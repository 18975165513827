import { motion } from "framer-motion"
import parties from '../images/parties.webp';
import boots from '../images/as.webp';
import party1 from '../images/party1.webp';
import party2 from '../images/party2.webp';
import Party from "../template/Party";

const Invite = () => {
    return(
        <div className='party'>
                <div className='headline'>
                    <img src={parties} alt='parties' />
                </div>
                <div className='party-img'>
                    <img src={party1} alt='' />
                    <img src={boots} alt='' />
                </div>
                <motion.div className='party-blurb' initial={{ y: '10vh', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} viewport={{ once: true }} transition={{ duration: 1 }}>
                    <h4>You are invited to:</h4>
                    <div className='party-head'>
                        <h2>Parties</h2>
                        <p>@</p>
                        <h4>Puddy Muddles</h4>
                    </div>
                <p>In addition to our weekly sessions, we also offer birthday party packages on weekends.
                    Imagine celebrating your child's special day in a beautiful and natural setting, surrounded
                    by friends and family.</p>
                <p>We understand that every child is unique, which is why we offer personalized themes to fit
                    your child's interests. Whether they love bugs, fairies, or dinosaurs, we have a theme that will
                    make their day extra special.</p>
                <p>In addition to the activities, the birthday child will receive their very own Puddy Muddles t-shirt and
                    personalised virtual invites. We want every child to feel special on their birthday, so we
                    make sure to provide these extra touches.</p>
                <p>While the children are having a blast, we also provide tea and coffee for the adults. So you
                    can sit back, relax, and enjoy watching your child have the time of their life. Contact us
                    today to book your child's unforgettable birthday party in the great outdoors!</p>
                <h4>Request a form via email @:</h4>
                <button onClick={(e) => {window.location.href ='mailto:puddymuddlesplaygroup@gmail.com';}}>Booking Requests</button>
                </motion.div>
                <Party />
                <div className='party-img'>
                    <img src={party2} alt='party' />
                </div>
            </div>
    )
}

export default Invite;