import { motion } from 'framer-motion';
import Navbar from '../components/Navbar'
import Darley from '../components/Darley';
import locations from '../images/locations.webp';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';
import Daisy from '../components/Daisy';
import fwr1 from '../images/flower1.png';
import fwr2 from '../images/flower2.png';
import dirt from '../images/growing & learning.png';

const LocationsPage = () => {
    const variants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }
    
    return(
        <motion.div variants={variants} initial="hidden" animate="visible">
        <header className="App-locations-header">
          <Navbar />
        </header>
            <div className='headline'>
                <img src={locations} alt='locations' />
            </div>
            <Darley />
            <div className='body-main'>
                <img src={fwr1} alt='flower' />
                <img src={dirt} alt='I dig dirt' />
                <img src={fwr2} alt='flower' />
            </div>
            <Daisy />
            <Reviews />
            <Footer />
        </motion.div>
    )
}

export default LocationsPage;