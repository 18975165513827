import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import InfoForm from '../template/InfoForm';

const InfoPage = () => {
        const variants = {
            hidden: {
                opacity: 0
            },
            visible: {
                opacity: 1,
                transition: { duration: 1, ease: 'easeInOut' }
            }
        }
    
        return(
            <motion.div variants={variants} initial="hidden" animate="visible">
                <Navbar />
                <div className='info-form'>
                    <div className='info'>
                        <InfoForm />
                    </div>
                </div>
                <Footer />
            </motion.div>
    )
}

export default InfoPage;